import moment from 'moment'
import VueI18n from '@/language/index'

export const shortcuts = [
  {
    text: VueI18n.global.t('三月内'),
    value: [
      moment().subtract(3, 'months').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ],
  },
  {
    text: VueI18n.global.t('半年内'),
    value: [
      moment().subtract(6, 'months').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ],
  },
  {
    text: VueI18n.global.t('一年内'),
    value: [
      moment().subtract(1, 'years').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ],
  },
  {
    text: VueI18n.global.t('全部'),
    value: ['2015-01-01', moment().format('YYYY-MM-DD')],
  },
]
